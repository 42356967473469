/**
 * @typedef {import('@modules/homepage/types/sbc').SbcDynamicMetaData} SbcDynamicMetaData
 */

/**
 * 按 Z 字形式排布，计算行数列数
 * sbc distribution 值的含义为数多少个折行: '4' | '5' ...
 * 
 * @param {{
 *   sbcDistribution: string,
 *   listLength: number,
 *   rowCount: number,
 * }} param
 */
export function setupRowsAndColsCountByDistributionInStyleZ({
  sbcDistribution,
  listLength,
  rowCount,
}) {
  let columnCount = Number(sbcDistribution) || '4'
  // Tips: row * 达换行数 可以称为 "一个 Grid 网格组"
  const oneGridCounts = rowCount * columnCount

  if (listLength < oneGridCounts) {
    // 不满一网格组，按实际个数展示
    rowCount = Math.floor(listLength / columnCount)
  } else {
    // 超过一网格组，最后一批不足一组的单独计算列数
    const screenCount = Math.floor(listLength / oneGridCounts)
    const rest = listLength % oneGridCounts
    
    if (rest === 0) {
      // 恰好整数倍屏数
      columnCount *= screenCount
    } else {
      const restCols = Math.ceil(rest / rowCount)
      columnCount = (columnCount * screenCount) + restCols
    }
  }

  return {
    columnCount,
    rowCount,
  }
}
