<script>
export default {
  name: 'CategoryRecommendSkeleton',
}
</script>

<template>
  <div class="category-recommend-skeleton__placeholder">
    <div
      v-for="row in 2"
      :key="row"
      class="category-recommend-skeleton"
    > 
      <div 
        v-for="col in 5"
        :key="col"
        class="line"
      >
        <div class="item"></div>
        <div class="name"></div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
/* stylelint-disable */

@vw: 375/100vw;

.category-recommend-skeleton {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  overflow: hidden;
  margin-bottom: 10 / @vw;

  &__placeholder {
    // 最小高度是一行入口项的高度
    min-height: 90px; 
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .line {
    width: 22%;
    display: inline-block;
    flex-shrink: 0;
    flex-grow: 0;
    margin-top: 4 / @vw;
  }
  .item {
    width: 54 / @vw;
    height: 54 / @vw;
    border-radius: 50%;
    margin: 0 auto;
    background-color: @sui_color_gray_weak1a;
  }
  .name {
    width: 54 / @vw;
    height: 0.36rem;
    background-color: @sui_color_gray_weak1a;
    margin: 4 / @vw auto;
  }
}
</style>
